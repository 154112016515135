import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home"; // Import Home component
import Tos from "./TermsOfService"; // Ensure the file is named exactly "Tos.js"
import Privacy from "./PrivacyPolicy"; // Ensure the file is named exactly "Privacy.js"
import CommunityGuidelines from "./CommunityGuideLines";
import Support from "./support";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tos" element={<Tos />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/support" element={<Support />} />

        <Route path="/community-guidelines" element={<CommunityGuidelines />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
